import pointInPolygon from 'point-in-polygon';
export function inYukon(latitude, longitude) {
	return pointInPolygon([latitude, longitude], yukonPolygon.latlngs);
	// if (val) return true;
	// return 'Not in Yukon';
}

//tests for in Yukon
// const pointInPolygonTestData = [
// 	{
// 		location: 'Vancouver, BC',
// 		point: [49.24966 - 123.11934],
// 		expectedResult: false,
// 	},
// 	{
// 		location: 'Whitehorse Yukon',
// 		point: [60.721188, -135.056839],
// 		expectedResult: true,
// 	},
// ];

// expect(inYukon(49.24966, -123.11).toBe(false));

export let yukonPolygon = {
	color: '#B0B0B0',
	latlngs: [
		[69.705, -141.025],
		[69.845, -141.025],
		[69.865, -141.015],
		[69.86, -140.84],
		[69.84, -140.64],
		[69.82, -140.53],
		[69.81, -140.38],
		[69.83, -140.115],
		[69.86, -139.035],
		[69.825, -138.715],
		[69.765, -138.485],
		[69.715, -138.37],
		[69.5, -138.025],
		[69.465, -137.29],
		[69.42, -136.575],
		[68.9, -136.43],
		[67.72, -136.43],
		[67.715, -136.415],
		[67.705, -136.41],
		[67.665, -136.42],
		[67.655, -136.27],
		[67.645, -136.26],
		[67.64, -136.225],
		[67.63, -136.21],
		[67.61, -136.21],
		[67.59, -136.18],
		[67.535, -136.185],
		[67.53, -136.16],
		[67.515, -136.15],
		[67.495, -136.15],
		[67.48, -136.165],
		[67.445, -136.16],
		[67.435, -136.18],
		[67.415, -136.185],
		[67.39, -136.125],
		[67.38, -136.115],
		[67.35, -136.12],
		[67.34, -136.11],
		[67.335, -136.08],
		[67.32, -136.065],
		[67.29, -136.065],
		[67.27, -136.08],
		[67.265, -136.095],
		[67.225, -136.105],
		[67.205, -136.145],
		[67.175, -136.17],
		[67.17, -136.185],
		[67.165, -136.175],
		[67.14, -136.17],
		[67.1, -136.2],
		[67.085, -136.18],
		[67.065, -136.19],
		[67.05, -136.16],
		[67.02, -136.15],
		[67.015, -133.805],
		[66.995, -133.8],
		[66.98, -133.81],
		[66.975, -133.84],
		[66.98, -133.885],
		[66.96, -134.06],
		[66.93, -134.03],
		[66.92, -133.98],
		[66.925, -133.925],
		[66.915, -133.885],
		[66.9, -133.88],
		[66.88, -133.855],
		[66.87, -133.855],
		[66.865, -133.82],
		[66.85, -133.79],
		[66.825, -133.775],
		[66.805, -133.78],
		[66.8, -133.75],
		[66.775, -133.745],
		[66.765, -133.755],
		[66.74, -133.755],
		[66.73, -133.765],
		[66.725, -133.81],
		[66.705, -133.825],
		[66.69, -133.86],
		[66.675, -133.855],
		[66.68, -133.835],
		[66.66, -133.785],
		[66.645, -133.775],
		[66.635, -133.705],
		[66.605, -133.66],
		[66.605, -133.625],
		[66.565, -133.555],
		[66.54, -133.56],
		[66.53, -133.58],
		[66.505, -133.695],
		[66.48, -133.69],
		[66.455, -133.7],
		[66.445, -133.73],
		[66.43, -133.73],
		[66.4, -133.765],
		[66.38, -133.755],
		[66.355, -133.755],
		[66.345, -133.765],
		[66.335, -133.8],
		[66.325, -133.805],
		[66.315, -133.805],
		[66.32, -133.75],
		[66.315, -133.64],
		[66.29, -133.59],
		[66.255, -133.555],
		[66.24, -133.55],
		[66.22, -133.565],
		[66.22, -133.545],
		[66.205, -133.53],
		[66.155, -133.535],
		[66.12, -133.57],
		[66.105, -133.635],
		[66.09, -133.645],
		[66.08, -133.665],
		[66.075, -133.595],
		[66.055, -133.585],
		[66.04, -133.545],
		[66.02, -133.545],
		[66.005, -133.555],
		[65.995, -133.57],
		[65.99, -133.605],
		[65.975, -133.61],
		[65.98, -133.5],
		[65.97, -133.46],
		[65.955, -133.435],
		[65.965, -133.42],
		[65.965, -133.385],
		[65.975, -133.395],
		[65.995, -133.395],
		[66.01, -133.375],
		[66.005, -133.28],
		[66.025, -133.26],
		[66.035, -133.16],
		[66.05, -133.145],
		[66.055, -133.095],
		[66.04, -133.06],
		[66.06, -133],
		[66.06, -132.97],
		[66.05, -132.96],
		[66.04, -132.91],
		[66.025, -132.895],
		[66, -132.89],
		[65.99, -132.9],
		[65.97, -132.9],
		[65.96, -132.92],
		[65.94, -132.93],
		[65.935, -132.945],
		[65.925, -132.95],
		[65.92, -132.88],
		[65.935, -132.87],
		[65.935, -132.85],
		[65.95, -132.845],
		[65.975, -132.82],
		[66.005, -132.775],
		[66.02, -132.73],
		[66.02, -132.705],
		[66.035, -132.695],
		[66.035, -132.665],
		[66.045, -132.65],
		[66.05, -132.615],
		[66.05, -132.56],
		[66.04, -132.535],
		[65.99, -132.51],
		[66, -132.455],
		[66.015, -132.43],
		[66.01, -132.33],
		[65.995, -132.31],
		[65.975, -132.31],
		[65.965, -132.3],
		[65.94, -132.31],
		[65.93, -132.335],
		[65.93, -132.38],
		[65.915, -132.39],
		[65.905, -132.41],
		[65.9, -132.455],
		[65.88, -132.5],
		[65.88, -132.52],
		[65.86, -132.535],
		[65.85, -132.53],
		[65.84, -132.475],
		[65.82, -132.445],
		[65.82, -132.42],
		[65.805, -132.405],
		[65.795, -132.355],
		[65.75, -132.31],
		[65.75, -132.28],
		[65.73, -132.27],
		[65.72, -132.255],
		[65.7, -132.255],
		[65.69, -132.215],
		[65.68, -132.205],
		[65.655, -132.2],
		[65.65, -132.185],
		[65.64, -132.18],
		[65.635, -132.155],
		[65.625, -132.145],
		[65.61, -132.145],
		[65.6, -132.155],
		[65.585, -132.155],
		[65.57, -132.2],
		[65.55, -132.21],
		[65.54, -132.24],
		[65.51, -132.255],
		[65.485, -132.28],
		[65.485, -132.29],
		[65.465, -132.295],
		[65.42, -132.335],
		[65.42, -132.35],
		[65.405, -132.36],
		[65.4, -132.395],
		[65.38, -132.41],
		[65.375, -132.46],
		[65.355, -132.49],
		[65.355, -132.505],
		[65.315, -132.535],
		[65.3, -132.53],
		[65.265, -132.555],
		[65.265, -132.65],
		[65.225, -132.675],
		[65.215, -132.695],
		[65.19, -132.695],
		[65.2, -132.665],
		[65.2, -132.615],
		[65.215, -132.6],
		[65.215, -132.545],
		[65.18, -132.495],
		[65.135, -132.51],
		[65.12, -132.525],
		[65.12, -132.535],
		[65.105, -132.525],
		[65.11, -132.35],
		[65.095, -132.34],
		[65.085, -132.32],
		[65.05, -132.315],
		[65.02, -132.33],
		[64.995, -132.41],
		[64.97, -132.425],
		[64.955, -132.49],
		[64.94, -132.46],
		[64.93, -132.46],
		[64.925, -132.44],
		[64.89, -132.44],
		[64.885, -132.46],
		[64.86, -132.48],
		[64.865, -132.52],
		[64.86, -132.54],
		[64.835, -132.56],
		[64.835, -132.59],
		[64.815, -132.565],
		[64.795, -132.56],
		[64.82, -132.52],
		[64.815, -132.46],
		[64.805, -132.445],
		[64.805, -132.435],
		[64.82, -132.42],
		[64.81, -132.395],
		[64.81, -132.35],
		[64.79, -132.32],
		[64.78, -132.275],
		[64.77, -132.265],
		[64.77, -132.23],
		[64.76, -132.205],
		[64.73, -132.165],
		[64.725, -132.135],
		[64.73, -132.065],
		[64.72, -132.05],
		[64.72, -132.03],
		[64.69, -131.995],
		[64.645, -131.97],
		[64.645, -131.96],
		[64.615, -131.94],
		[64.61, -131.905],
		[64.6, -131.89],
		[64.59, -131.89],
		[64.58, -131.83],
		[64.56, -131.82],
		[64.565, -131.75],
		[64.555, -131.685],
		[64.54, -131.67],
		[64.48, -131.67],
		[64.47, -131.68],
		[64.47, -131.715],
		[64.46, -131.735],
		[64.435, -131.76],
		[64.435, -131.795],
		[64.415, -131.82],
		[64.395, -131.82],
		[64.4, -131.785],
		[64.395, -131.725],
		[64.405, -131.715],
		[64.41, -131.69],
		[64.405, -131.655],
		[64.395, -131.64],
		[64.41, -131.6],
		[64.41, -131.55],
		[64.42, -131.54],
		[64.415, -131.48],
		[64.45, -131.48],
		[64.47, -131.465],
		[64.47, -131.44],
		[64.485, -131.395],
		[64.475, -131.375],
		[64.48, -131.32],
		[64.47, -131.3],
		[64.465, -131.255],
		[64.445, -131.23],
		[64.44, -131.21],
		[64.435, -131.14],
		[64.4, -131.11],
		[64.4, -131.09],
		[64.385, -131.065],
		[64.36, -131.065],
		[64.335, -131.015],
		[64.31, -131.015],
		[64.305, -131.03],
		[64.295, -131.03],
		[64.29, -131.005],
		[64.28, -130.995],
		[64.28, -130.975],
		[64.265, -130.96],
		[64.26, -130.94],
		[64.245, -130.925],
		[64.215, -130.92],
		[64.21, -130.865],
		[64.195, -130.835],
		[64.165, -130.84],
		[64.16, -130.87],
		[64.135, -130.88],
		[64.13, -130.91],
		[64.12, -130.905],
		[64.105, -130.875],
		[64.055, -130.875],
		[64.065, -130.855],
		[64.065, -130.75],
		[64.05, -130.73],
		[64.035, -130.73],
		[64.005, -130.755],
		[63.99, -130.755],
		[63.99, -130.73],
		[63.97, -130.68],
		[63.97, -130.635],
		[63.955, -130.625],
		[63.955, -130.61],
		[63.945, -130.6],
		[63.95, -130.53],
		[63.935, -130.515],
		[63.935, -130.485],
		[63.91, -130.455],
		[63.91, -130.44],
		[63.895, -130.41],
		[63.88, -130.4],
		[63.88, -130.385],
		[63.85, -130.35],
		[63.855, -130.295],
		[63.875, -130.29],
		[63.895, -130.265],
		[63.895, -130.22],
		[63.885, -130.21],
		[63.89, -130.16],
		[63.875, -130.125],
		[63.865, -130.115],
		[63.82, -130.125],
		[63.805, -130.1],
		[63.785, -130.1],
		[63.745, -130.13],
		[63.735, -130.13],
		[63.735, -130.2],
		[63.725, -130.2],
		[63.71, -130.215],
		[63.7, -130.245],
		[63.705, -130.315],
		[63.69, -130.31],
		[63.68, -130.29],
		[63.68, -130.245],
		[63.705, -130.175],
		[63.715, -130.165],
		[63.715, -130.135],
		[63.69, -130.115],
		[63.665, -130.115],
		[63.65, -130.095],
		[63.65, -130.08],
		[63.63, -130.075],
		[63.63, -130.055],
		[63.64, -130.04],
		[63.64, -129.99],
		[63.625, -129.935],
		[63.615, -129.925],
		[63.595, -129.925],
		[63.585, -129.94],
		[63.58, -129.905],
		[63.56, -129.89],
		[63.545, -129.89],
		[63.53, -129.875],
		[63.515, -129.845],
		[63.485, -129.825],
		[63.475, -129.795],
		[63.44, -129.8],
		[63.43, -129.82],
		[63.43, -129.845],
		[63.44, -129.855],
		[63.445, -129.89],
		[63.405, -129.9],
		[63.4, -129.885],
		[63.38, -129.875],
		[63.35, -129.895],
		[63.35, -129.915],
		[63.32, -129.93],
		[63.3, -130],
		[63.3, -130.03],
		[63.315, -130.035],
		[63.32, -130.065],
		[63.305, -130.07],
		[63.295, -130.09],
		[63.295, -130.125],
		[63.27, -130.125],
		[63.275, -130.075],
		[63.265, -130.055],
		[63.27, -130.05],
		[63.265, -130.025],
		[63.24, -130],
		[63.225, -130],
		[63.22, -129.98],
		[63.21, -129.975],
		[63.225, -129.94],
		[63.22, -129.92],
		[63.21, -129.915],
		[63.195, -129.885],
		[63.165, -129.885],
		[63.15, -129.865],
		[63.155, -129.84],
		[63.145, -129.815],
		[63.11, -129.815],
		[63.105, -129.805],
		[63.105, -129.78],
		[63.095, -129.775],
		[63.095, -129.75],
		[63.085, -129.73],
		[63.085, -129.695],
		[63.1, -129.675],
		[63.1, -129.62],
		[63.09, -129.61],
		[63.07, -129.605],
		[63.055, -129.585],
		[63.03, -129.585],
		[63.015, -129.605],
		[63.01, -129.63],
		[62.985, -129.65],
		[62.96, -129.645],
		[62.915, -129.71],
		[62.9, -129.69],
		[62.875, -129.695],
		[62.865, -129.75],
		[62.865, -129.74],
		[62.85, -129.73],
		[62.85, -129.69],
		[62.835, -129.675],
		[62.82, -129.675],
		[62.815, -129.655],
		[62.79, -129.645],
		[62.775, -129.615],
		[62.72, -129.605],
		[62.72, -129.595],
		[62.71, -129.59],
		[62.715, -129.555],
		[62.705, -129.54],
		[62.695, -129.54],
		[62.69, -129.51],
		[62.675, -129.495],
		[62.66, -129.5],
		[62.65, -129.47],
		[62.64, -129.465],
		[62.62, -129.465],
		[62.61, -129.475],
		[62.605, -129.42],
		[62.585, -129.4],
		[62.585, -129.385],
		[62.56, -129.36],
		[62.555, -129.31],
		[62.54, -129.29],
		[62.535, -129.24],
		[62.53, -129.225],
		[62.51, -129.21],
		[62.515, -129.195],
		[62.5, -129.165],
		[62.45, -129.16],
		[62.43, -129.22],
		[62.43, -129.25],
		[62.415, -129.25],
		[62.405, -129.265],
		[62.39, -129.21],
		[62.375, -129.195],
		[62.365, -129.195],
		[62.345, -129.215],
		[62.35, -129.24],
		[62.33, -129.26],
		[62.305, -129.265],
		[62.295, -129.28],
		[62.28, -129.28],
		[62.26, -129.255],
		[62.255, -129.235],
		[62.235, -129.225],
		[62.23, -129.19],
		[62.22, -129.18],
		[62.18, -129.19],
		[62.165, -129.205],
		[62.165, -129.215],
		[62.15, -129.19],
		[62.14, -129.19],
		[62.13, -129.17],
		[62.125, -129.125],
		[62.13, -129.1],
		[62.145, -129.09],
		[62.135, -129.035],
		[62.15, -128.995],
		[62.14, -128.97],
		[62.12, -128.95],
		[62.14, -128.915],
		[62.14, -128.88],
		[62.12, -128.86],
		[62.115, -128.835],
		[62.09, -128.79],
		[62.12, -128.77],
		[62.115, -128.74],
		[62.15, -128.71],
		[62.155, -128.675],
		[62.14, -128.635],
		[62.16, -128.62],
		[62.16, -128.59],
		[62.125, -128.515],
		[62.1, -128.49],
		[62.085, -128.485],
		[62.085, -128.46],
		[62.065, -128.42],
		[62.055, -128.42],
		[62.04, -128.36],
		[62, -128.355],
		[61.995, -128.325],
		[61.98, -128.31],
		[61.97, -128.31],
		[61.975, -128.3],
		[61.955, -128.245],
		[61.93, -128.235],
		[61.885, -128.19],
		[61.87, -128.185],
		[61.885, -128.15],
		[61.885, -128.13],
		[61.875, -128.12],
		[61.885, -128.1],
		[61.885, -128.06],
		[61.87, -128.045],
		[61.865, -128.01],
		[61.855, -128],
		[61.84, -128],
		[61.825, -127.975],
		[61.8, -127.975],
		[61.78, -128.015],
		[61.755, -128.02],
		[61.755, -128.005],
		[61.735, -127.99],
		[61.725, -127.955],
		[61.705, -127.925],
		[61.7, -127.875],
		[61.685, -127.87],
		[61.68, -127.845],
		[61.665, -127.83],
		[61.65, -127.83],
		[61.64, -127.8],
		[61.625, -127.79],
		[61.625, -127.775],
		[61.615, -127.765],
		[61.62, -127.72],
		[61.61, -127.68],
		[61.59, -127.67],
		[61.58, -127.655],
		[61.56, -127.65],
		[61.56, -127.62],
		[61.55, -127.61],
		[61.555, -127.59],
		[61.545, -127.565],
		[61.535, -127.565],
		[61.52, -127.52],
		[61.53, -127.455],
		[61.525, -127.455],
		[61.53, -127.265],
		[61.52, -127.23],
		[61.485, -127.195],
		[61.49, -127.17],
		[61.485, -127.135],
		[61.46, -127.115],
		[61.445, -127.11],
		[61.43, -127.115],
		[61.415, -127.1],
		[61.395, -127.1],
		[61.39, -127.06],
		[61.35, -127.03],
		[61.315, -127.04],
		[61.3, -127.025],
		[61.245, -127.02],
		[61.25, -126.99],
		[61.23, -126.965],
		[61.2, -126.975],
		[61.19, -126.99],
		[61.185, -126.985],
		[61.16, -126.99],
		[61.13, -127.05],
		[61.125, -127.085],
		[61.09, -127.06],
		[61.075, -127.07],
		[61.07, -127.06],
		[61.055, -127.055],
		[61.055, -127.04],
		[61.085, -127.025],
		[61.07, -126.91],
		[61.045, -126.905],
		[61.015, -126.92],
		[61.005, -126.905],
		[60.99, -126.905],
		[60.97, -126.885],
		[60.935, -126.875],
		[60.92, -126.885],
		[60.865, -126.885],
		[60.865, -126.865],
		[60.85, -126.85],
		[60.84, -126.85],
		[60.84, -126.84],
		[60.82, -126.825],
		[60.8, -126.825],
		[60.785, -126.845],
		[60.795, -126.815],
		[60.79, -126.755],
		[60.8, -126.745],
		[60.79, -126.625],
		[60.8, -126.62],
		[60.815, -126.59],
		[60.815, -126.57],
		[60.835, -126.53],
		[60.83, -126.5],
		[60.805, -126.45],
		[60.8, -126.39],
		[60.79, -126.375],
		[60.81, -126.33],
		[60.795, -126.3],
		[60.81, -126.25],
		[60.83, -126.265],
		[60.84, -126.285],
		[60.85, -126.29],
		[60.87, -126.285],
		[60.87, -126.19],
		[60.885, -126.135],
		[60.885, -126.1],
		[60.875, -126.08],
		[60.845, -126.075],
		[60.84, -126.085],
		[60.83, -126.08],
		[60.835, -126.055],
		[60.825, -126.01],
		[60.865, -125.99],
		[60.875, -125.95],
		[60.92, -125.895],
		[60.93, -125.86],
		[60.93, -125.83],
		[60.865, -125.79],
		[60.87, -125.775],
		[60.865, -125.75],
		[60.855, -125.745],
		[60.855, -125.725],
		[60.84, -125.71],
		[60.82, -125.71],
		[60.82, -125.65],
		[60.83, -125.635],
		[60.835, -125.6],
		[60.85, -125.59],
		[60.85, -125.56],
		[60.83, -125.535],
		[60.82, -125.47],
		[60.8, -125.435],
		[60.795, -125.395],
		[60.8, -125.31],
		[60.805, -125.295],
		[60.825, -125.29],
		[60.83, -125.235],
		[60.845, -125.225],
		[60.845, -125.215],
		[60.86, -125.205],
		[60.87, -125.185],
		[60.865, -125.08],
		[60.86, -125.065],
		[60.845, -125.055],
		[60.845, -125.03],
		[60.875, -124.985],
		[60.875, -124.885],
		[60.9, -124.895],
		[60.905, -124.905],
		[60.925, -124.905],
		[60.945, -124.89],
		[60.95, -124.86],
		[60.97, -124.855],
		[60.99, -124.83],
		[60.99, -124.79],
		[60.97, -124.765],
		[60.965, -124.67],
		[60.985, -124.64],
		[60.97, -124.57],
		[60.955, -124.555],
		[60.925, -124.555],
		[60.895, -124.52],
		[60.82, -124.515],
		[60.82, -124.49],
		[60.81, -124.475],
		[60.79, -124.465],
		[60.765, -124.465],
		[60.74, -124.495],
		[60.735, -124.525],
		[60.72, -124.535],
		[60.71, -124.575],
		[60.695, -124.585],
		[60.685, -124.575],
		[60.655, -124.58],
		[60.635, -124.53],
		[60.625, -124.525],
		[60.615, -124.49],
		[60.56, -124.45],
		[60.555, -124.41],
		[60.535, -124.395],
		[60.485, -124.395],
		[60.485, -124.27],
		[60.47, -124.19],
		[60.41, -124.185],
		[60.38, -124.2],
		[60.365, -124.19],
		[60.355, -124.195],
		[60.345, -124.18],
		[60.315, -124.165],
		[60.305, -124.145],
		[60.275, -124.135],
		[60.275, -124.125],
		[60.25, -124.095],
		[60.215, -124.09],
		[60.2, -124.065],
		[60.18, -124.055],
		[60.17, -124.035],
		[60.17, -124.005],
		[60.155, -123.985],
		[60.115, -123.985],
		[60.09, -123.975],
		[60.02, -123.8],
		[60.01, -123.79],
		[59.985, -123.795],
		[59.98, -139.07],
		[60.07, -139.21],
		[60.085, -139.22],
		[60.33, -139.095],
		[60.315, -139.69],
		[60.16, -139.98],
		[60.285, -140.45],
		[60.205, -140.505],
		[60.2, -140.525],
		[60.24, -140.775],
		[60.29, -141.015],
		[64.76, -141.025],
		[69.705, -141.025],
	],
};
