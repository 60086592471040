import { render, staticRenderFns } from "./ContributingResourceTypesSelect.vue?vue&type=template&id=57f9683d&scoped=true&lang=pug"
import script from "./ContributingResourceTypesSelect.vue?vue&type=script&lang=js"
export * from "./ContributingResourceTypesSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57f9683d",
  null
  
)

export default component.exports