import { render, staticRenderFns } from "./ThemesEditor.vue?vue&type=template&id=c2609aac&scoped=true&lang=pug"
import script from "./ThemesEditor.vue?vue&type=script&lang=js"
export * from "./ThemesEditor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2609aac",
  null
  
)

export default component.exports