import { render, staticRenderFns } from "./OwnershipTypesSelect.vue?vue&type=template&id=efad28b0&scoped=true&lang=pug"
import script from "./OwnershipTypesSelect.vue?vue&type=script&lang=js"
export * from "./OwnershipTypesSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efad28b0",
  null
  
)

export default component.exports